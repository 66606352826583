import React, { FunctionComponent, useMemo, memo } from 'react';
import { Medication, removeMedication } from '../medicationsSlice';
import { DateTime } from 'luxon';
import { MedicationConsumptionListItemMemo } from '../medicationConsumptionListItem/medicationConsumptionListItem';
import { ConsumptionForm } from '../consumptionForm/consumptionForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectNextSafeMedicationDate,
  getIntervalTypeText,
} from '../../medicationUtil';
import { AddToCalendarLink } from '../addToCalendarLink/addToCalendarLink';
import './medicationListItem.scss';
import { RootState } from '../../../rootReducer';

type Props = {
  medication: Medication;
};

export const MedicationListItem: FunctionComponent<Props> = props => {
  const { medication } = props;

  const nextSafeDateSelector = useMemo(
    () => makeSelectNextSafeMedicationDate(medication.id),
    [medication.id]
  );

  const nextSafeDate = useSelector((state: RootState) =>
    nextSafeDateSelector(state)
  );

  const getNextSafeDateText = () => {
    if (nextSafeDate.diffNow().as('seconds') <= 1) {
      return 'Now';
    } else {
      return nextSafeDate.toLocaleString(DateTime.DATETIME_SHORT);
    }
  };

  const dispatch = useDispatch();
  const removeMedicationClicked = () => {
    dispatch(removeMedication(medication));
  };

  return (
    <div>
      <div key={medication.id} className="medicationContainer">
        <div className="headerRow">
          <div className="medName">{medication.medName}</div>
          <button className="removeButton" onClick={removeMedicationClicked}>
            Remove
          </button>
        </div>
        <div>
          Schedule: {medication.intervalUnits} {medication.unitText} /{' '}
          {medication.intervalPeriod}{' '}
          {getIntervalTypeText(
            medication.intervalType,
            medication.intervalPeriod > 1
          )}
        </div>
        <div>
          Next safe consumption date:{' '}
          <span className="bold">{getNextSafeDateText()}</span> (
          <AddToCalendarLink
            medication={medication}
            nextSafeDate={nextSafeDate}
          ></AddToCalendarLink>
          )
        </div>
        <div>
          Consumption Log: <hr></hr>
          {medication.consumptions.map(consumption => (
            <MedicationConsumptionListItemMemo
              key={consumption.id}
              consumption={consumption}
              medication={medication}
            ></MedicationConsumptionListItemMemo>
          ))}
          <hr />
          <ConsumptionForm medication={medication}></ConsumptionForm>
        </div>
      </div>
    </div>
  );
};

export const MedicationListItemMemo = memo(MedicationListItem);
