import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import './newMedicationForm.scss';
import {
  Medication,
  addMedication,
  HourInterval,
  DayInterval,
  MinuteInterval,
  IntervalType,
} from '../medicationsSlice';
import { v4 } from 'uuid';
import { getIntervalTypeText } from '../../medicationUtil';

export const NewMedicationForm: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [nameState, setNameState] = useState('');
  const [unitState, setUnitState] = useState('mg');
  const [intervalPeriodState, setIntervalPeriodState] = useState(1);
  const [intervalUnitsState, setIntervalUnitsState] = useState(1);
  const [intervalTypeState, setIntervalTypeState] = useState(DayInterval);

  const addNewMedicationClicked = () => {
    const newMedication: Medication = {
      id: v4(),
      medName: nameState,
      unitText: unitState,
      color: 'red',
      intervalPeriod: intervalPeriodState,
      intervalUnits: intervalUnitsState,
      intervalType: intervalTypeState as IntervalType,
      consumptions: [],
    };
    dispatch(addMedication(newMedication));
  };

  return (
    <div className="newMedicationFormContainer">
      <div className="title">Add New Medication:</div>
      <div className="rootInputContainer">
        <div className="inputContainer">
          <div className="label">Medication name:</div>{' '}
          <div className="input">
            <input
              value={nameState}
              onChange={e => setNameState(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="inputContainer">
          <div className="label">Unit:</div>
          <div className="input">
            <input
              value={unitState}
              onChange={e => setUnitState(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="inputContainer">
          <div className="label">Schedule:</div>
          <div className="input">
            <input
              className="narrowInput"
              value={intervalUnitsState}
              onChange={e => setIntervalUnitsState(e.target.valueAsNumber)}
              type="number"
              min={1}
            ></input>{' '}
            {unitState} every{' '}
            <input
              className="narrowInput"
              type="number"
              value={intervalPeriodState}
              onChange={e => setIntervalPeriodState(e.target.valueAsNumber)}
              min={1}
            ></input>{' '}
            <select
              defaultValue={intervalTypeState}
              onChange={e =>
                setIntervalTypeState(
                  Number.parseInt(e.target.value) as IntervalType
                )
              }
            >
              <option value={DayInterval}>
                {getIntervalTypeText(DayInterval, intervalPeriodState > 1)}
              </option>
              <option value={HourInterval}>
                {getIntervalTypeText(HourInterval, intervalPeriodState > 1)}
              </option>
              <option value={MinuteInterval}>
                {getIntervalTypeText(MinuteInterval, intervalPeriodState > 1)}
              </option>
            </select>
          </div>
        </div>
        <div className="inputContainer">
          <div className="label"></div>
          <div className="input">
            <button onClick={addNewMedicationClicked}>
              Add New Medication
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
