import React, { FunctionComponent } from 'react';
import { Medication } from '../medicationsSlice';
import { DateTime } from 'luxon';

interface Props {
  medication: Medication;
  nextSafeDate: DateTime;
}

export const AddToCalendarLink: FunctionComponent<Props> = props => {
  const { medication, nextSafeDate } = props;

  const lastConsumption =
    medication.consumptions[medication.consumptions.length - 1];

  const title = encodeURIComponent(`Take ${medication.medName}`);

  let details = '';
  if (medication.consumptions.length > 0) {
    details = `You last took ${lastConsumption.units} ${medication.unitText} ${
      medication.medName
    } on ${DateTime.fromMillis(lastConsumption.timestamp).toLocaleString(
      DateTime.DATETIME_SHORT
    )}`;
  }
  details = encodeURIComponent(
    details +
      `\n\nMedication schedule: ${medication.intervalUnits} ${
        medication.unitText
      } every ${
        medication.intervalPeriod > 1
          ? `${medication.intervalPeriod} days`
          : 'day'
      }`
  );

  const safeDateString = encodeURIComponent(
    nextSafeDate
      .toUTC()
      .toISO()
      .replace(/-/g, '')
      .replace(/:/g, '')
      .split('.')[0] + 'Z'
  );

  const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${details}&dates=${safeDateString}/${safeDateString}`;

  return (
    <a href={url} rel="noopener noreferrer" target="_blank">
      Add to Google Calendar
    </a>
  );
};
