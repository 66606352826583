import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { v4 } from "uuid";

type CounterState = {
  counter: number;
  byId: Record<string, number>;
  allIds: string[];
};

const initialId = v4();
let initialState: CounterState = {
  counter: 0,
  byId: {
    [initialId]: 1,
  },
  allIds: [initialId],
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setCounter(state, action: PayloadAction<number>) {
      state.counter = action.payload;
    },

    incrementCounter(state) {
      state.counter++;
    },

    addNumber(state, action: PayloadAction<{ id: string; number: number }>) {
      const { id, number } = action.payload;
      state.byId[id] = number;
      state.allIds.push(id);
    },
  },
});

const selectCounter = (state: CounterState) => state.counter;

export const selectTheCounter = createSelector([selectCounter], (counter) => {
  return counter;
});

export const selectAllNumbers = createSelector(
  [(state: CounterState) => state],
  (state) => {
    return state.allIds.map((id) => ({ id, number: state.byId[id] }));
  }
);

export const { setCounter, incrementCounter, addNumber } = counterSlice.actions;

export default counterSlice.reducer;
