import React, { FunctionComponent, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  addMedicationConsumption,
  Medication,
  MedicationConsumption,
} from '../medicationsSlice';
import './consumptionForm.scss';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';

interface Props {
  medication: Medication;
}

export const ConsumptionForm: FunctionComponent<Props> = props => {
  const { medication } = props;

  const unitsRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const timeRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const logConsumptionClicked = () => {
    if (!unitsRef.current?.value) {
      alert('Please enter the units');
      return;
    }

    if (!dateRef.current?.value) {
      alert('Please enter the date');
      return;
    }

    const amount = unitsRef.current?.valueAsNumber;
    const date = DateTime.fromISO(dateRef.current?.value);
    const time = DateTime.fromISO(timeRef.current?.value || '');
    const timestamp = date
      .plus({ hours: time.hour, minutes: time.minute, second: time.second })
      .toMillis();

    unitsRef.current.value = unitsRef.current.defaultValue;
    dateRef.current.value = dateRef.current.defaultValue;

    const newConsumption: MedicationConsumption = {
      timestamp,
      units: amount,
      id: v4(),
    };

    dispatch(
      addMedicationConsumption({ consumption: newConsumption, medication })
    );
  };

  useEffect(() => {
    if (timeRef.current)
      timeRef.current.value = DateTime.local().toLocaleString(
        DateTime.TIME_24_SIMPLE
      );
  }, []);

  return (
    <div className="consumptionForm">
      <div className="inputContainer">
        <div className="label">Amount:</div>
        <div className="input">
          <input
            className="unitInput"
            type="number"
            ref={unitsRef}
            min={0.0}
            defaultValue={medication.intervalUnits}
          ></input>
          {medication.unitText}
        </div>
      </div>
      <div className="inputContainer">
        <div className="label">Date:</div>
        <div className="input">
          <input
            type="date"
            ref={dateRef}
            max={DateTime.local().toISODate()}
            defaultValue={DateTime.local().toISODate()}
          ></input>{' '}
          <input type="time" ref={timeRef} defaultValue={'12:00'}></input>
        </div>
      </div>
      <div className="inputContainer">
        <button className="logButton" onClick={logConsumptionClicked}>
          Log Consumption
        </button>
      </div>
    </div>
  );
};
