import {
  Medication,
  MedicationConsumption,
  IntervalType,
  DayInterval,
  HourInterval,
  MinuteInterval,
} from './medications/medicationsSlice';
import { DateTime } from 'luxon';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

interface SelectorType {
  medication: Medication;
  consumptions: MedicationConsumption[];
}

export const makeSelectNextSafeMedicationDate = (medicationId: string) => {
  return createSelector(
    [(state: RootState) => state.medications.byId[medicationId]],
    medication => {
      const consumptions = medication.consumptions;
      const timePeriod = medication.intervalPeriod * medication.intervalType;
      let totalConsumed = 0;
      let totalTimePassed = 0;

      if (consumptions.length === 0) return DateTime.local();

      totalConsumed = consumptions[0].units;

      for (let i = 1; i < consumptions.length; i++) {
        totalConsumed += consumptions[i].units;
        totalTimePassed += Math.min(
          timePeriod,
          consumptions[i].timestamp - consumptions[i - 1].timestamp
        );
      }

      totalTimePassed +=
        new Date().getTime() - consumptions[consumptions.length - 1].timestamp;

      let difference =
        totalConsumed -
        (totalTimePassed / timePeriod) * medication.intervalUnits;

      return DateTime.local().plus({
        milliseconds: Math.max(
          0,
          (difference / medication.intervalUnits) * timePeriod
        ),
      });
    }
  );
};

export const getIntervalTypeText = (
  interval: IntervalType,
  plural: boolean
) => {
  switch (interval) {
    case DayInterval:
      return plural ? 'days' : 'day';
    case HourInterval:
      return plural ? 'hours' : 'hour';
    case MinuteInterval:
      return plural ? 'minutes' : 'minute';
    default:
      return 'Unknown';
  }
};

// export const selectNextSafeMedicationDate = makeSelectNextSafeMedicationDate();
