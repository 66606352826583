import React, { FunctionComponent } from "react";
import { MedicationsList } from "./features/medications/medicationsList";
import "./App.css";
import { NewMedicationForm } from "./features/medications/newMedicationForm/newMedicationForm";

const App: FunctionComponent = () => {
  return (
    <div className="App">
      <NewMedicationForm></NewMedicationForm>
      <div className="medicationListHeader">Medication list:</div>
      <MedicationsList></MedicationsList>
    </div>
  );
};

export default App;
