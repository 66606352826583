import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import medicationsReducer from "./features/medications/medicationsSlice";

const rootReducer = combineReducers({
  counter: counterReducer,
  medications: medicationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
