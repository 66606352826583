import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { MedicationListItemMemo } from './medicationListItem/medicationListItem';
import './medicationsList.scss';

export const MedicationsList: FunctionComponent = () => {
  const medicationsList = useSelector((state: RootState) =>
    state.medications.allMedicationsIds.map(id => state.medications.byId[id])
  );

  return (
    <>
      {medicationsList.map(med => (
        <MedicationListItemMemo
          key={med.id}
          medication={med}
        ></MedicationListItemMemo>
      ))}
    </>
  );
};
