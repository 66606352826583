import React, { FunctionComponent, memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  MedicationConsumption,
  Medication,
  removeMedicationConsumption,
} from '../medicationsSlice';
import './medicationConsumptionListItem.scss';
import { DateTime } from 'luxon';

interface Props {
  consumption: MedicationConsumption;
  medication: Medication;
}

export const MedicationConsumptionListItem: FunctionComponent<Props> = props => {
  const { consumption, medication } = props;

  const dispatch = useDispatch();

  const removeConsumption = () => {
    dispatch(
      removeMedicationConsumption({
        consumption,
        medication,
      })
    );
  };

  return (
    <div className="consumptionListItem">
      <div>
        <button className="removeButton" onClick={removeConsumption}>
          X
        </button>
      </div>
      <div>
        <span className="light">
          {DateTime.fromMillis(consumption.timestamp).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
        </span>
        : {consumption.units} {medication.unitText}
      </div>
    </div>
  );
};

export const MedicationConsumptionListItemMemo = memo(
  MedicationConsumptionListItem
);
