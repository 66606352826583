import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export const DayInterval = 86400000;
export const HourInterval = 3600000;
export const MinuteInterval = 60000;
export type IntervalType =
  | typeof DayInterval
  | typeof HourInterval
  | typeof MinuteInterval;

type MedicationsState = {
  byId: Record<string, Medication>;
  allMedicationsIds: string[];
};

export type Medication = {
  id: string;
  medName: string;
  unitText: string;
  color: string;
  intervalUnits: number;
  intervalPeriod: number;
  intervalType: IntervalType;
  consumptions: MedicationConsumption[];
};

export type MedicationConsumption = {
  id: string;
  units: number;
  timestamp: number;
};

const buildInitialState = (): MedicationsState => {
  const medication1: Medication = {
    id: v4(),
    medName: 'Test Cough Medicine',
    color: 'red',
    unitText: 'oz',
    intervalUnits: 5,
    intervalPeriod: 3,
    intervalType: DayInterval,
    consumptions: [
      {
        timestamp: new Date().getTime(),
        units: 100,
        id: v4(),
      },
    ],
  };

  const medication2: Medication = {
    id: v4(),
    medName: 'Test Cough Medicine',
    color: 'red',
    unitText: 'oz',
    intervalUnits: 5,
    intervalPeriod: 3,
    intervalType: HourInterval,
    consumptions: [
      {
        timestamp: new Date().getTime(),
        units: 5,
        id: v4(),
      },
    ],
  };

  return {
    byId: {
      [medication1.id]: medication1,
      [medication2.id]: medication2,
    },
    allMedicationsIds: [medication1.id, medication2.id],
  };
};
let initialMedicationsState: MedicationsState = buildInitialState();

export type AddMedicationConsumptionPayload = {
  medication: Medication;
  consumption: MedicationConsumption;
};

export type RemoveMedicationConsumption = {
  medication: Medication;
  consumption: MedicationConsumption;
};

const medicationsSlice = createSlice({
  name: 'medications',
  initialState: initialMedicationsState,
  reducers: {
    addMedication(state, action: PayloadAction<Medication>) {
      const medication = action.payload;
      state.byId[medication.id] = medication;
      state.allMedicationsIds.push(medication.id);
    },
    removeMedication(state, action: PayloadAction<Medication>) {
      const medication = action.payload;
      delete state.byId[medication.id];
      state.allMedicationsIds = state.allMedicationsIds.filter(
        id => id !== medication.id
      );
    },
    addMedicationConsumption(
      state,
      action: PayloadAction<AddMedicationConsumptionPayload>
    ) {
      const { medication, consumption } = action.payload;
      state.byId[medication.id].consumptions.push(consumption);
      state.byId[medication.id].consumptions.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    },
    removeMedicationConsumption(
      state,
      action: PayloadAction<RemoveMedicationConsumption>
    ) {
      const { medication, consumption } = action.payload;

      state.byId[medication.id].consumptions = state.byId[
        medication.id
      ].consumptions.filter(c => c.id !== consumption.id);
    },
  },
});

export const {
  addMedication,
  removeMedication,
  addMedicationConsumption,
  removeMedicationConsumption,
} = medicationsSlice.actions;

export default medicationsSlice.reducer;
