import {
  configureStore,
  getDefaultMiddleware,
  AnyAction,
  Middleware,
} from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { get, set } from 'idb-keyval';

const indexedDbKey = 'redux-state-v2';

function indexedDbCache(): Middleware {
  return state => next => (action: AnyAction) => {
    next(action);

    // TODO: Debounce / rate limit
    set(indexedDbKey, state.getState());
  };
}

export const createStoreFromCache = async () => {
  const previousState = await get(indexedDbKey);
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware().concat(indexedDbCache()),
    preloadedState: previousState as RootState,
  });
};

// export type AppDispatch = typeof store.dispatch;
// export default store;
